// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import "slick-carousel";

// custom

jQuery(document).ready(function () {

    jQuery(".testimonial-slider").slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        prevArrow: "<div class='slick-prev revart-arrow'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' style='fill:#cd1e25;'><path d='M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z'/></svg></div>",
        nextArrow: "<div class='slick-next'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512' style='fill:#cd1e25;'><path d='M64 448c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L178.8 256L41.38 118.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25l-160 160C80.38 444.9 72.19 448 64 448z'/></svg></div>",
        dots: false,
        adaptiveHeight: true,
        autoplay: true,
        autoplaySpeed: 6500
    });
});

/* Menu Toggle */
jQuery(document).ready(function () {
    jQuery("button.navbar-toggler").on("click", function () {
        jQuery("body").toggleClass("menu-overflow");
    });
});
